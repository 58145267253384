module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
;
__p += '\r\n\r\n<section class="ny-banner">\r\n    <div class="banner-img">\r\n        <b style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b>\r\n    </div>\r\n    <div class="banner-title">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n</section>';

}
return __p
}