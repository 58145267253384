module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="header">\r\n        <div class="left-logo"><a href="/"><img src="' +
((__t = (require('./images/logo.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n        <div class="right-nav">\r\n            <a href="/">Home</a>\r\n            <a href="about.html" class="active">About Us</a>\r\n            <a href="products.html">Products</a>\r\n            <a href="list_article.html">News Center</a>\r\n            <a href="quality.html">Quality & Safety</a>\r\n            <a href="contact.html">Contact Us</a>\r\n        </div>\r\n        <div class="global"><a href="#"><i class="iconfont icon-global"></i>cn</a></div>\r\n    </div>\r\n    <div class="open-menu" data-open="cmenu">\r\n        <div class="open-menu-text"><span>menu</span><span>close</span></div>\r\n        <div class="hamburger hamburger-11">\r\n            <div></div>\r\n        </div>\r\n    </div>\r\n    <div class="fixed-menu">\r\n        <div class="menu-left">\r\n            <h1>Paprimur Qingdao Foods Co., Ltd.</h1>\r\n            <h2>We have been provide customers around the world with high-quality and guaranteed products</h2>\r\n        </div>\r\n        <div class="menu-right">\r\n            <nav>\r\n                <a href="/">Home</a>\r\n                <a href="about.html">About Us</a>\r\n                <a href="products.html">Products</a>\r\n                <a href="list_article.html">News Center</a>\r\n                <a href="quality.html">Quality & Safety</a>\r\n                <a href="contact.html">Contact Us</a>\r\n            </nav>\r\n        </div>\r\n    </div>\r\n</header>';

}
return __p
}