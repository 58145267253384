import './index.scss'

// $(document).scroll(function () { 
//     var scroH = $(document).scrollTop();  
//     var viewH = $(window).height();        
//     var contentH = $(document).height();       
//     if(scroH >100){          
//     }         
//     if (contentH - (scroH + viewH) <= 100){                     
//     }           
//     if (contentH = (scroH + viewH)){                    
//     }         
// });

//侧栏菜单隐藏显示
$('[data-open="cmenu"]').on('click', function () {
    if ($('header .hamburger').hasClass('active')) {
        $('header .open-menu').removeClass('active')
        $('header .hamburger').removeClass('active')
        $('header .fixed-menu').removeClass('active')
    } else {
        $('header .open-menu').addClass('active')
        $('header .hamburger').addClass('active')
        $('header .fixed-menu').addClass('active')
    }
})





